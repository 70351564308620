@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom global styles can be added here */
body {
  @apply antialiased;
}

/* Optional: Custom scrollbar */
::-webkit-scrollbar {
  @apply w-2 bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-blue-300 rounded-full;
}